<template>
<div class="container">
     <table class="table">
  <thead>
    <tr>
      
      <th scope="col">Bet Code</th>
      <th scope="col">Stake</th>
      <th scope="col">Placed At</th>
      <th scope="col">Payout</th>
      <th scope="col">Status</th>
      <th scope="col">Won</th>
    </tr>
  </thead>
  <tbody>
      <tr v-for="(bet,index) in casinoBets" :key="index">
          <td>{{bet.code}}</td>
          <td>{{bet.stake}}</td>
          <td>{{format_date(bet.created_at)}}</td>
          <td>{{bet.amount_won}}</td>
          <td>
            
            <b-button variant="primary" v-if="bet.status == false">
              Active
            </b-button>
             <b-button variant="success" v-if="bet.status == true">
              Settled
            </b-button>

          </td>
          <td>
            <b-button variant="danger" v-if="bet.status == false && bet.won == true">
              Lost
            </b-button>
            <b-button variant="warning" v-if="bet.status == false && bet.won == false">
              active
            </b-button>
             <b-button variant="success" v-if="bet.status == true && bet.won == true">
              Won
            </b-button>
          </td>
          
      </tr>
  </tbody>
          </table>
</div>
  
</template>

<script>
import {mapGetters} from "vuex"
import moment from "moment"
export default {
    computed:{
        ...mapGetters(['casinoBets'])
    },
    mounted(){
        this.$store.dispatch('getCasinoBets')
    },
    methods:{
         format_date(value){
         if (value) {
           return moment(String(value)).format('Y-M-D H:MM')
          }
      },
    }
    
}
</script>

<style>

</style>